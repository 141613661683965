import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable, catchError, from, switchMap, throwError } from 'rxjs';

import { AuthState, SetTokens } from '../state';

import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {

  private readonly store = inject(Store);
  private readonly router = inject(Router);
  private readonly authService = inject(AuthService);

  private get accessToken() {
    return this.store.selectSnapshot(AuthState.accessToken);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req.clone({
      headers: req.headers.set('Authorization', `Bearer ${this.accessToken}`),
    })).pipe(
      catchError((error: HttpErrorResponse) => {
        switch (error.status) {
          case 401:
          case 403:
            return this.authService.refreshToken(this.store.selectSnapshot(AuthState.refreshToken)!).pipe(
              switchMap(tokens => this.store.dispatch(new SetTokens(tokens))),
              switchMap(() => next.handle(req.clone({
                headers: req.headers.set('Authorization', `Bearer ${this.accessToken}`),
              }))),
              catchError(err => from(this.router.navigate(['/auth/logout'])).pipe(
                switchMap(() => throwError(() => err)),
              )),
            );
        }

        return throwError(() => error);
      }),
    );
  }
}
