import { Injectable } from '@angular/core';
import {
  Action,
  NgxsOnInit,
  Selector,
  State,
  StateContext,
  StateToken,
  createPropertySelectors,
} from '@ngxs/store';

import { Locale, SetLocale, SetRole, Tokens } from '@trtl/state/user';

import { SetTokens } from './auth.actions';

export interface AuthStateModel {
  tokens: Tokens | null;
}

const initialState = {
  tokens: null,
};

export const AUTH_STATE = new StateToken<AuthStateModel>('auth');

@State<AuthStateModel>({
  name: AUTH_STATE,
  defaults: initialState,
})
@Injectable()
export class AuthState implements NgxsOnInit {

  private static slices = createPropertySelectors(AUTH_STATE);

  @Selector([AuthState.slices.tokens])
  static accessToken(tokens: Tokens | null) { return tokens?.accessToken }

  @Selector([AuthState.slices.tokens])
  static refreshToken(tokens: Tokens | null) { return tokens?.refreshToken }

  ngxsOnInit({ dispatch }: StateContext<AuthStateModel>): void {
    const tokens = JSON.parse(localStorage.getItem('tokens') ?? 'null') as Tokens | null;

    tokens && dispatch([
      new SetTokens(tokens),
      new SetRole(
        JSON.parse(atob(tokens.idToken.split('.')[1]))['custom:role'],
      ),
    ]);
    dispatch(new SetLocale(localStorage.getItem('locale') as Locale ?? 'en'));
  }

  @Action(SetTokens)
  setTokens(
    { patchState }: StateContext<AuthStateModel>,
    { tokens }: SetTokens,
  ) {
    patchState({ tokens });
    tokens
      ? localStorage.setItem('tokens', JSON.stringify(tokens))
      : localStorage.removeItem('tokens');
  }
}
