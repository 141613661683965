import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ENV } from '@trtl/env';
import { Tokens } from '../models';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  private readonly env = inject(ENV);
  private readonly http = inject(HttpClient);

  public refreshToken(refreshToken: string) {
    return this.http.post<Tokens>(
      `${this.env.api}/api/v1/patient/auth/refresh`,
      {
        refreshToken,
        clientId: this.env.aws.userPoolWebClientId,
      },
    );
  }
}
